/* requires:
polyfill.js
*/

// for active menu highlighting
(function () {
  const a = document.getElementById('nav').getElementsByTagName('a');
  let loc = window.location.href;
  for (let i = 0; i < a.length; i++) {
    if (a[i].href == loc) {
      a[i].classList.add('is-active');
    }
  }
})();

document
  .getElementById('nav')
  .querySelector('ul')
  .insertAdjacentHTML(
    'beforebegin',
    "<button id='menutoggle' aria-label='Menu' role='button'><div class='hamburger-box'><div class='hamburger-inner'></div></div><span>Menu</span></button>"
  );
const menuToggle = document.getElementById('menutoggle');
const activeClass = 'is-active';

// toggling the mobile menu
document.addEventListener(
  'click',
  function (event) {
    const targetEl = event.target;

    // for entire mobile menu
    if (targetEl.closest('#menutoggle')) {
      menuToggle.classList.toggle(activeClass);
      const el = document.querySelectorAll('#nav span.submenu, #nav a.submenu, #nav ul.submenu');
      for (let i = 0; i < el.length; i++) {
        el[i].classList.remove(activeClass);
      }
      event.preventDefault();
    }

    // if the #menuToggle is visible
    if (menuToggle.offsetWidth > 0 && menuToggle.offsetHeight > 0) {
      // for menu dropdown
      if (targetEl.closest('#nav span.submenu, #nav a.submenu')) {
        const closestEl = targetEl.closest('#nav span.submenu, #nav a.submenu');
        closestEl.classList.toggle(activeClass);
      }

      if (!targetEl.closest('#nav')) {
        if (menuToggle.classList.contains(activeClass)) {
          hideMenu();
        }
      }
    }
  },
  false
);

function hideMenu() {
  var el = document.querySelectorAll('#menutoggle, #menutoggle + ul, #nav span.submenu, #nav a.submenu, #nav ul.submenu');
  for (let i = 0; i < el.length; i++) {
    el[i].classList.remove(activeClass);
  }
}

let resizeTimer;
window.addEventListener(
  'resize',
  function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      if (menuToggle.offsetWidth <= 0 && menuToggle.offsetHeight <= 0) {
        // if the #menuToggle is hidden
        hideMenu();
      }
    }, 250);
  },
  false
);
